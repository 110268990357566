.list-item > div.rounded-corners {
    position: relative;
    height: 12rem;
}
.list-item > div.rounded-corners img {
  position: absolute;
  width: 100%;
  transition: transform 2s ease-out 0.5ms;
}
.list-item:hover > div.rounded-corners img {
  transform: translateY(-300px);
}
.rounded-corners {
  border-radius: 21px;
}
img {
  vertical-align: middle;
  border-style: none
}
.links {
  text-align: center;
}